<template>
  <div>
    <div class="discoverMapContent">
      <div
        class="test"
        @click="() => ((showImg = fm.url), (modalVisible = true))"
      >
        <img
          src="../assets/test.jpg"
          style="width: 100%; height: 75%; border-radius: 10px 10px 0 0"
        />
        <div class="contentText">
          <div class="textLeft">
            <div>{{ fm.longitude }}</div>
            <div>{{ fm.latitude }}</div>
          </div>
          <img :src="fm.ma" style="width: 50px; height: 50px" />
        </div>
      </div>
    </div>
    <a-modal
      v-model="modalVisible"
      centered
      @ok="() => (modalVisible = false)"
      :footer="null"
      :closable="false"
      width="none"
    >
      <div style="display: flex">
        <img src="../assets/test.jpg" />
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin: 0 36px 0 60px;
          "
        >
          <img src="@/assets/maMax.png" style="width: 100%" />
          <span
            style="
              margin-top: 10px;
              font-size: 16px;
              font-weight: 600;
              text-align: center;
              color: #000000;
              letter-spacing: 0.06px;
            "
            >扫描二维码添加地标</span
          >
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "Test1",
  data() {
    return {
      modalVisible: false,
      showImg: "",
    };
  },
  props: ["fm"],
  methods: {
    setModal1Visible(modalVisible) {
      this.modalVisible = modalVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.discoverMap {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
  .discoverMapContent {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .test {
      cursor: pointer;
      margin: 30px;
      width: 277px;
      height: 342px;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 20px 80px 0px rgba(0, 0, 0, 0.15);
      .contentText {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        .textLeft {
          font-size: 14px;
          font-weight: 500;
          color: #000000;
          letter-spacing: 0.06px;
        }
      }
    }
  }
}
</style>
